"use client";

import React, {
  ImgHTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useTheme } from "@mui/material/styles";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { getLogoPathByLocale } from "~/constants/i18n";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { Nullable } from "~/types/general.types";
import { getImageSrcSetWithAmplienceTemplates } from "~/utils/image/get-image-src-set-with-amplience-templates";

// eslint-disable-next-line import/namespace
import { ImageProps, ImageTemplates } from "./types";

type ImagePropsExtended = ImageProps &
  ImgHTMLAttributes<Element> & {
    imageTemplates?: ImageTemplates;
    fallbacks?: (Nullable<string> | undefined)[];
  };

export const Image: React.FC<ImagePropsExtended> = ({
  className,
  src,
  alt,
  title,
  fallbacks,
  imageTemplates,
  ...props
}) => {
  const locale = useParams<GlobalPathParams>()?.locale;
  const logoSrc = useMemo(() => {
    return getLogoPathByLocale(locale);
  }, [locale]);
  if (!src) {
    src = logoSrc;
  }

  const theme = useTheme();

  const fallbacksRef = useRef(fallbacks);

  const [hasRendered, setHasRendered] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleError = useCallback(
    (event: SyntheticEvent<HTMLImageElement>): void => {
      event.currentTarget.onerror = null;
      const newSrc = fallbacksRef.current?.shift() || "/assets/images/no-image.png";
      event.currentTarget.src = newSrc;
      event.currentTarget.srcset = newSrc;
    },
    [],
  );

  // solution for SSR img event issue: https://github.com/facebook/react/issues/15446#issuecomment-887701200
  useEffect(() => {
    if (imgRef.current && hasRendered) {
      imgRef.current.src = src || "";
    }
  }, [src, hasRendered]);

  useEffect(() => {
    setHasRendered(true);
  }, []);
  if (imageTemplates) {
    const imageSrcSetWithTemplates = getImageSrcSetWithAmplienceTemplates(
      src,
      imageTemplates,
    );
    return (
      <img
        sizes={[
          `(max-width: ${theme.breakpoints.values.xs}) ${theme.breakpoints.values.xs}px`,
          `(max-width: ${theme.breakpoints.values.sm}) ${theme.breakpoints.values.sm}px`,
          `(max-width: ${theme.breakpoints.values.md}) ${theme.breakpoints.values.md}px`,
          `(max-width: ${theme.breakpoints.values.lg}) ${theme.breakpoints.values.lg}px`,
        ].join(",")}
        className={className}
        ref={imgRef}
        srcSet={imageSrcSetWithTemplates ?? undefined}
        src={src}
        alt={prepareTextAttribute(alt, true)}
        title={prepareTextAttribute(title)}
        onError={handleError}
        loading="lazy"
        {...props}
      />
    );
  }
  return (
    <img
      className={className}
      src={src}
      alt={prepareTextAttribute(alt, true)}
      title={prepareTextAttribute(title)}
      onError={handleError}
      loading="lazy"
      {...props}
    />
  );
};
