"use client";

import React, { MouseEvent, useCallback, useContext } from "react";

import Cookies from "js-cookie";
import { useParams, useRouter } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { ImageCommon } from "~/bff/types/ImageCommon";
import { CombinedLink } from "~/components/combined-link/component";
import { Logo } from "~/components/logo/component";
import { LINKED_LOGO_CLASSES } from "~/components/logo/components/linked-logo/constants";
import { LogoSizeKey } from "~/components/logo/types";
import { FUNCTIONAL_COOKIES } from "~/constants/cookie-categories";
import { Routes } from "~/constants/request";
import { OPTANON_CONSENT_COOKIES } from "~/constants/user-cookies";
import { DySplitterHomeNavigationCampaignContext } from "~/context/dy-splitter-home-navigation-campaign/context";
import { getLinkUrl } from "~/helpers/locales";
import { getCookieCategories } from "~/helpers/one-trust/cookie-parser";
import { getLastVisibleCLP } from "~/utils/get-last-visible-clp";

import { LinkedLogoContainer } from "./styled";

export interface LinkedLogoProps {
  className?: string;
  image?: ImageCommon | null;
  size?: LogoSizeKey;
  href: string;
  onClick?: () => void;
}

export const LinkedLogo: React.FC<LinkedLogoProps> = ({
  className,
  image,
  size,
  href = Routes.HOME,
  onClick,
}) => {
  const router = useRouter();
  const { locale } = useParams<GlobalPathParams>();
  const dyCampaignContext = useContext(DySplitterHomeNavigationCampaignContext);

  const internalOnClick = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault();
      if (onClick) {
        onClick();
      }

      const OptanonConsent = Cookies.get(OPTANON_CONSENT_COOKIES);
      const lastVisitedLCP = getLastVisibleCLP(Cookies);
      if (!OptanonConsent) {
        return router.push(getLinkUrl(locale, href));
      }

      const cookieCategories = getCookieCategories(OptanonConsent);
      const isFunctionalCookieAccepted = cookieCategories[FUNCTIONAL_COOKIES];
      const lastVisitedClpForCurrentLocale = lastVisitedLCP.find(
        ({ locale: localeLocal }) => localeLocal === locale,
      );

      const isHomeNavigationEnabled = dyCampaignContext?.isHomeNavigationEnabled;
      const isSplitterHomeNavigationCampaignEnabled =
        dyCampaignContext?.isSplitterHomeNavigationCampaignEnabled;

      if (isSplitterHomeNavigationCampaignEnabled) {
        dyCampaignContext.reportLogoClickEngagement();
      }

      if (
        !isFunctionalCookieAccepted ||
        !lastVisitedClpForCurrentLocale ||
        isHomeNavigationEnabled
      ) {
        return router.push(getLinkUrl(locale, href));
      }

      router.push(getLinkUrl(locale, `${lastVisitedClpForCurrentLocale.path}`));
    },
    [onClick, dyCampaignContext, router, href, locale],
  );

  return (
    <LinkedLogoContainer className={className} size={size}>
      <CombinedLink
        href={href}
        underline="none"
        className={LINKED_LOGO_CLASSES.logoLink}
        onClick={internalOnClick}
      >
        <Logo image={image} size={size} className={LINKED_LOGO_CLASSES.logoImage} />
      </CombinedLink>
    </LinkedLogoContainer>
  );
};
