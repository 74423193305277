import { getLocaleFromUrl } from "~/helpers/locales";

export const checkIsLinkAbsolute = (href: string) => {
  return href?.includes("http");
};
export const checkIsExternalHostName = (href: string, host: string) => {
  return !href?.includes(host);
};

export const checkIsLinkLocaleMatchPageLocale = (
  href: string,
  routerLocale: string,
) => {
  const linkLocale = getLocaleFromUrl(href);
  if (!linkLocale) {
    return true;
  }
  return linkLocale === routerLocale;
};
