import { ReactNode } from "react";

export const prepareNoFollow = (
  children: ReactNode,
): [string | undefined, ReactNode] => {
  if (typeof children === "string" && children.includes(" [nofollow]")) {
    children = children.replace(" [nofollow]", "");
    return ["nofollow", children];
  } else if (
    Array.isArray(children) &&
    children.every(
      (children) => typeof children === "string" && children.includes(" [nofollow]"),
    )
  ) {
    children = children.map((children) => children.replace(" [nofollow]", ""));
    return ["nofollow", children];
  }
  return [undefined, children];
};
