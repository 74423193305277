import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

import type { Dimensions } from "./types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "logo-mobile",
  [WindowFormat.tablet]: "logo-tablet",
  [WindowFormat.desktop]: "logo-regulardesktop",
  [WindowFormat.wideDesktop]: "logo-largedesktop",
};

export const dimensions: Dimensions = {
  height: {
    small: 13,
    medium: 18,
    large: 28,
  },
  width: {
    small: 97.9,
    medium: 135.5,
    large: 213.5,
  },
};
