import { useMemo } from "react";

import { LOCALES } from "~/constants/i18n";
import {
  checkIsExternalHostName,
  checkIsLinkAbsolute,
  checkIsLinkLocaleMatchPageLocale,
} from "~/helpers/link/link-checker";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";

export const useLink = (
  href: string,
  locale: LOCALES,
): { shouldDisableLink: boolean } => {
  const baseUrl = AzureConfigurator?.getConfig(locale)?.frontEndBaseUrl;
  const url = new URL(baseUrl as string);
  const isLinkLocaleMatchPageLocale = checkIsLinkLocaleMatchPageLocale(href, locale);

  const isLinkAbsolute = checkIsLinkAbsolute(href);

  const isExternalHostName = checkIsExternalHostName(href, url.host);

  const shouldDisableLink = useMemo(() => {
    if (isLinkAbsolute && isExternalHostName) {
      return false;
    }
    return !isLinkLocaleMatchPageLocale;
  }, [isExternalHostName, isLinkAbsolute, isLinkLocaleMatchPageLocale]);

  return {
    shouldDisableLink,
  };
};
