import { startCase } from "lodash";

import { BreadcrumbsItem } from "~/bff/types/BreadcrumbsItem";
import { Product } from "~/bff/types/Product";
import { Nullable } from "~/types/general.types";

export const removeLocaleFromPath = (path?: string): string => {
  if (!path) {
    return "";
  }
  return path.replace(/^\/[a-z]{2}-[a-z]{2}/, "");
};

export const getPageCategoriesTitles = (
  breadcrumbs?: Nullable<Nullable<BreadcrumbsItem>[]> | undefined,
  path?: string,
): Record<string, string> => {
  const categoriesFromBreadcrumbs = breadcrumbs
    ?.slice()
    .reverse()
    .map((breadcrumb) => breadcrumb?.title);
  const pathWithoutLocale = removeLocaleFromPath(path);
  const categoriesFromPath = pathWithoutLocale
    ?.split("/")
    ?.map((category) => startCase(category?.split("-")?.join(" ")));
  return {
    pageCategory: categoriesFromBreadcrumbs?.[0] ?? categoriesFromPath?.[2] ?? null,
    pageSubCategory:
      categoriesFromBreadcrumbs?.[1] ?? categoriesFromPath?.[3] ?? null,
    pageSubSubCategory:
      categoriesFromBreadcrumbs?.[2] ?? categoriesFromPath?.[4] ?? null,
    pageSubSubSubCategory:
      categoriesFromBreadcrumbs?.[3] ?? categoriesFromPath?.[5] ?? null,
  };
};

export const getPageCategoriesString = (...args: string[]) => {
  return args.filter((arg) => arg).join("|");
};

type GetPageCategory = (categorySlug?: string) => {
  pageCategory?: string;
  pageSubCategory?: string;
  pageSubSubCategory?: string;
  pageSubSubSubCategory?: string;
};

/**
 * Extracts category information from a category slug string.
 * This function interprets the input `categorySlug`, expected to be formatted with category data
 * separated by '___'. It splits this string and assigns each segment to a specific category level,
 * from top-level category to potential third sub-category level.
 *
 * For example, a `categorySlug` of 'electronics___laptops___gaming___high-end' would be split into:
 * - pageCategory: 'electronics'
 * - pageSubCategory: 'laptops'
 * - pageSubSubCategory: 'gaming'
 * - pageSubSubSubCategory: 'high-end'
 *
 * If the `categorySlug` is undefined or an empty string, or if it does not contain enough segments
 * to fill all category levels, those levels will be set to `undefined`.
 *
 * @param {string | undefined} categorySlug - A string that represents the hierarchical path of product categories,
 *                                            separated by '___'. This can be undefined, which would return all category
 *                                            levels as undefined.
 * @returns {{ pageCategory?: string, pageSubCategory?: string, pageSubSubCategory?: string, pageSubSubSubCategory?: string }}
 *          An object containing keys for each category level that may have a string or undefined as their values.
 *          If `categorySlug` is not provided, all returned categories will be undefined.
 */
export const getPageCategory: GetPageCategory = (categorySlug) => {
  if (categorySlug) {
    const [
      pageCategory = undefined,
      pageSubCategory = undefined,
      pageSubSubCategory = undefined,
      pageSubSubSubCategory = undefined,
    ] = categorySlug.split("___").map((item) => (item === "" ? undefined : item));
    return {
      pageCategory,
      pageSubCategory,
      pageSubSubCategory,
      pageSubSubSubCategory,
    };
  }

  return {
    pageCategory: undefined,
    pageSubCategory: undefined,
    pageSubSubCategory: undefined,
    pageSubSubSubCategory: undefined,
  };
};

interface Category {
  id: string;

  [key: string]: unknown;
}

type CategoryPath = Category[];

type CategoryPathsDisplayableArray = CategoryPath[];

/**
 * Extracts and reformats a category ID from structured category path data.
 * The input should be a JSON-encoded string representing an array of arrays,
 * where each array contains objects each with an 'id' field. The function
 * extracts the 'id' from the last object of the first array, and then performs
 * replacements on this 'id' to conform to a specific format by replacing colons
 * with triple underscores.
 *
 * For example, the JSON string might look like:
 * '[[
 *     {"id": "c::category1:subcategory1"},
 *     {"id": "c::category1:subcategory1:subsubcategory1"}
 * ], [
 *     {"id": "c::category2:subcategory2"}
 * ]]'
 * This represents two main categories, each with one or more subcategories.
 *
 * The function will transform an 'id' like "c::electronics:cameras" to "electronics___cameras".
 *
 * @param {string} categoryPathsDisplayable - A JSON string representing structured category path data.
 * @returns {string} A formatted string derived from the 'id' property of the last object in the first array.
 *                   The format replaces colons with triple underscores ('___') and removes the 'c::' prefix.
 *                   Returns an empty string if any checks fail or if the data does not conform to the expected structure.
 */
export const getCategoriesFromPathsDisplayable = (
  categoryPathsDisplayable: string,
): string => {
  let categoryPathsDisplayableArray: CategoryPathsDisplayableArray;
  try {
    categoryPathsDisplayableArray = JSON.parse(categoryPathsDisplayable);

    if (!Array.isArray(categoryPathsDisplayableArray)) {
      return "";
    }
  } catch (e) {
    return "";
  }

  const firstArray = categoryPathsDisplayableArray[0];
  if (!Array.isArray(firstArray) || firstArray.length === 0) {
    return "";
  }

  const lastObject = firstArray[firstArray.length - 1];
  if (!lastObject?.id || typeof lastObject.id !== "string") {
    return "";
  }

  return lastObject.id.replace("c::", "").replace(/:/g, "___");
};

/**
 * Extracts the primary category slug from product data.
 * If the displayable category path 'categoryPathsDisplayable' is available and is a string,
 * a specialized function is used to transform the format of this string. Otherwise,
 * it attempts to directly extract the slug of the first category from the product's category list.
 *
 * The 'categoryPathsDisplayable' string should be a JSON-encoded string representing an array of arrays,
 * where each inner array contains objects with an 'id' field formatted as "c::<category>:<subcategory>:<subsubcategory>".
 * For example:
 * '[
 *   [
 *     {"id": "c::electronics:cameras"},
 *     {"id": "c::electronics:cameras:DSLR"}
 *   ],
 *   [
 *     {"id": "c::computers:laptops"}
 *   ]
 * ]'
 *
 * This function parses the 'categoryPathsDisplayable' to extract and format the last 'id' from the first array,
 * replacing colons with triple underscores ('___') to match the desired output format.
 *
 * @param {Product & { categoryPathsDisplayable?: string }} product - The product object,
 *                                                                   which may include the 'categoryPathsDisplayable' property.
 * @returns {string} The slug of the primary category if available, formatted as "category___subcategory",
 *                   or an empty string if no valid category slug can be extracted.
 */
export const getMainCategorySlugFromProductData = (
  product?: Product & {
    categoryPathsDisplayable?: string;
  },
): string => {
  if (
    product?.categoryPathsDisplayable &&
    typeof product?.categoryPathsDisplayable === "string"
  ) {
    return getCategoriesFromPathsDisplayable(product.categoryPathsDisplayable);
  }

  return product?.categories?.[0]?.slug || "";
};
