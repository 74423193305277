"use client";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { dimensions } from "~/components/logo/constants";
import { LogoSizeKey } from "~/components/logo/types";

import { LINKED_LOGO_CLASSES } from "./constants";

interface LinkedLogoContainerProps {
  size?: LogoSizeKey;
}
enum LogoSize {
  small = dimensions.height.small,
  medium = dimensions.height.medium,
  large = dimensions.height.large,
}

export const LinkedLogoContainer = styled(Box)<LinkedLogoContainerProps>(
  ({ size }) => ({
    [`& .${LINKED_LOGO_CLASSES.logoLink}`]: {
      display: "flex",
      alignItems: "center",
      maxHeight: "100%",
      height: size ? LogoSize[size] : LogoSize.large,
    },
    [`& .${LINKED_LOGO_CLASSES.logoImage}`]: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
  }),
);
