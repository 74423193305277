import { SHOPPING_BAG_SCROLL } from "~/constants/session-storage";
import { Anchoring } from "~/hooks/anchoring/types";

const getTab = () => {
  // eslint-disable-next-line no-restricted-globals
  return window?.location?.search?.split("tab=")?.[1]?.split("&")?.[0];
};

export const currentPage = (
  pathname: string,
): Anchoring["page"] | null | undefined => {
  const tab = getTab();
  if (pathname === "/search") {
    if (tab === "articles") {
      return "SRP_ARTICLES";
    }
    if (tab === "products") {
      return "SRP_PRODUCTS";
    }
  } else if (pathname?.startsWith("/e/")) {
    const splitPathname = pathname.split("/");
    if (splitPathname.length === 3 && splitPathname?.[2]?.includes("insp")) {
      return "ALP";
    }
    return "ATP";
  } else if (pathname?.startsWith("/c/")) {
    return "PLP";
  } else if (pathname?.startsWith("/r/")) {
    return "RBP";
  } else if (pathname === "/shopping-list") {
    return "SHOPPING_LIST";
  } else if (pathname === "/shopping-bag") {
    return "SHOPPING_BAG";
  } else {
    return null;
  }

  return null;
};

export const persistScrollPosition = (id: string, pathname: string): void => {
  sessionStorage.setItem(currentPage(pathname) ?? "", id);
};

export const updateNextScrollPosition = (): void => {
  sessionStorage.setItem(
    SHOPPING_BAG_SCROLL,
    // eslint-disable-next-line no-restricted-globals
    JSON.stringify({ x: window.scrollX, y: window.scrollY }),
  );
};
