import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";

const DEFAULT_EXCEPTIONAL_LINK = "/help/s";

export const useExceptionalLinks = (): { exceptionalLinks: string[] } => {
  const { locale } = useParams<GlobalPathParams>();
  const config = useAzureConfigurator(locale) ?? {};
  const exceptionalLinksConfig = config?.appConfig?.exceptionalLinks;
  if (!exceptionalLinksConfig) {
    return { exceptionalLinks: [DEFAULT_EXCEPTIONAL_LINK] };
  }

  const exceptionalLinks = JSON.parse(exceptionalLinksConfig);

  if (!Array.isArray(exceptionalLinks)) {
    return { exceptionalLinks: [DEFAULT_EXCEPTIONAL_LINK] };
  }

  return { exceptionalLinks: [DEFAULT_EXCEPTIONAL_LINK, ...exceptionalLinks] };
};
