import { ReactNode } from "react";

import { LinkProps } from "@mui/material/Link/Link";

import { internalHosts } from "~/constants/internalHosts";

import { prepareNoFollow } from "./prepareNoFollow";

interface ExtendedLinkProps extends LinkProps {
  ariaLabel?: string;
  tabIndex?: number;
}

export const prepareLinkProps = (
  props: ExtendedLinkProps,
  children: ReactNode,
  baseUrl: string,
): {
  props: LinkProps;
  children: ReactNode;
} => {
  const { target, href, ...otherProps } = props;
  const resultProps = { ...otherProps, target, href };

  const [rel, preparedChildren] = prepareNoFollow(children);
  resultProps.rel = rel;
  children = preparedChildren;

  if (target || !href) {
    return { props: resultProps, children };
  }

  try {
    const { host: linkHost } = new URL(href);
    const { host: currentHost } = new URL(baseUrl);
    if (!(internalHosts.includes(linkHost) || linkHost === currentHost)) {
      resultProps.target = "_blank";
    }
    return { props: resultProps, children };
  } catch (err) {
    return { props: resultProps, children };
  }
};
