"use client";

import React, { useMemo } from "react";

import { Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { LinkProps } from "@mui/material/Link/Link.d";
import { noop } from "lodash";
import NextLink from "next/link";
import { useParams, usePathname } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { useExceptionalLinks } from "~/components/combined-link/hooks/use-exceptional-link";
import { LOCALES } from "~/constants/i18n";
import { removeLocaleFromPath } from "~/helpers/get-page-categories-titles";
import { isMailToHref } from "~/helpers/is-mail-to-href/index";
import {
  checkIsExternalHostName,
  checkIsLinkAbsolute,
} from "~/helpers/link/link-checker";
import { getLinkUrl } from "~/helpers/locales";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { currentPage, updateNextScrollPosition } from "~/hooks/anchoring/helpers";
import { useLink } from "~/hooks/use-link/hook";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { prepareLinkProps } from "~/utils/link/prepareLinkProps";

export interface extendedLinkProps extends LinkProps {
  locale?: LOCALES;
  ariaLabel?: string;
  tabIndex?: number;
}

export const CombinedLink = React.forwardRef<HTMLAnchorElement, extendedLinkProps>(
  ({ children, ...props }, ref) => {
    const { locale: routerLocale } = useParams<GlobalPathParams>();
    const baseUrl = AzureConfigurator?.getConfig(routerLocale)?.frontEndBaseUrl;
    const url = new URL(baseUrl as string);
    const pathname = usePathname();
    const { href, className, locale, onClick, ariaLabel, id } = props;
    const { exceptionalLinks } = useExceptionalLinks();
    const { shouldDisableLink } = useLink(href ?? "", routerLocale);

    const { props: linkProps, children: linkChildren } = useMemo(
      () => prepareLinkProps(props, children, baseUrl as string),
      [props, children, baseUrl],
    );

    const _onClick = (e: React.MouseEvent<HTMLAnchorElement> | React.KeyboardEvent<HTMLAnchorElement>) => {
      if (onClick && !shouldDisableLink) {
        onClick(e as React.MouseEvent<HTMLAnchorElement>);
      }
      const pathnameWithoutLocale = removeLocaleFromPath(pathname);
      if (
        currentPage(pathnameWithoutLocale) === "SHOPPING_BAG" &&
        !linkProps?.target
      ) {
        updateNextScrollPosition();
      }
    };

    const _onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (e.key === "Enter") {
        _onClick(e);
      }
    };

    if (linkChildren && shouldDisableLink) {
      return (
        <Typography
          component="span"
          id={id}
          ref={ref}
          className={className}
          {...getTestAutomationProps("link")}
          {...linkProps}
          href={undefined}
          data-disabled={true}
          aria-disabled="true"
          onClick={noop}
          sx={{
            textDecoration: "none",
            cursor: "auto",
            fontSize: "inherit",
            "&:hover": { textDecoration: "none" },
            "&:before, &:after": { display: "none" },
          }}
        >
          {linkChildren ?? children}
        </Typography>
      );
    }

    const link = (
      <MuiLink
        id={id}
        ref={ref}
        className={className}
        {...getTestAutomationProps("link")}
        {...linkProps}
        onClick={_onClick}
        onKeyDown={_onKeyDown}
        aria-label={ariaLabel}
      >
        {linkChildren ?? children}
      </MuiLink>
    );

    const isLinkAbsolute = checkIsLinkAbsolute(href ?? "");
    const isExternalHostName = checkIsExternalHostName(href ?? "", url.host);
    const isExceptionalLink = exceptionalLinks?.includes(href ?? "");

    const shouldUseNextLink =
      (!isLinkAbsolute || !isExternalHostName || IS_SERVER) &&
      href &&
      !isExceptionalLink;

    return shouldUseNextLink ? (
      <NextLink
        prefetch={false}
        href={isMailToHref(href) ? href : getLinkUrl(locale ?? routerLocale, href)}
        passHref
        locale={false}
        legacyBehavior
      >
        {link}
      </NextLink>
    ) : (
      link
    );
  },
);

CombinedLink.displayName = "CombinedLink";
