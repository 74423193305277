import Cookies from "cookies";
import { default as clientCookies } from "js-cookie";
import { ReadonlyRequestCookies } from "next/dist/server/web/spec-extension/adapters/request-cookies";

import { LAST_VISITED_CLP_PAGE } from "~/constants/user-cookies";

type UserCookies = Cookies | clientCookies.CookiesStatic;

export const getLastVisibleCLP = (
  userCookies: UserCookies = clientCookies,
): Array<{ locale: string; path: string }> => {
  try {
    const lastVisitedClpCookie = userCookies.get(LAST_VISITED_CLP_PAGE);
    return lastVisitedClpCookie
      ? JSON.parse(decodeURIComponent(lastVisitedClpCookie))
      : [];
  } catch (error) {
    if ((userCookies as clientCookies.CookiesStatic).remove instanceof Function) {
      (userCookies as clientCookies.CookiesStatic).remove(LAST_VISITED_CLP_PAGE);
    }

    return [];
  }
};

export const getLastVisitedClpServerSide = (
  userCookies: ReadonlyRequestCookies,
): Array<{ locale: string; path: string }> => {
  try {
    const lastVisitedClpCookie = userCookies.get(LAST_VISITED_CLP_PAGE);
    return lastVisitedClpCookie
      ? JSON.parse(decodeURIComponent(lastVisitedClpCookie?.value))
      : [];
  } catch (error) {
    userCookies.delete(LAST_VISITED_CLP_PAGE);

    return [];
  }
};
