import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const responsiveImageWidthParams = {
  [WindowFormat.mobile]: "560w",
  [WindowFormat.tablet]: "1024w",
  [WindowFormat.desktop]: "1920w",
  [WindowFormat.wideDesktop]: "2048w",
};

export const getImageSrcSetWithAmplienceTemplates = (
  src: string | undefined,
  templates: ImageTemplates,
): string | null => {
  if (!templates) {
    return null;
  }

  return src?.includes("https")
    ? [
        `${src}?$${templates[WindowFormat.mobile]}$&fmt=auto ${
          responsiveImageWidthParams[WindowFormat.mobile]
        }`,
        `${src}?$${templates[WindowFormat.tablet]}$&fmt=auto ${
          responsiveImageWidthParams[WindowFormat.tablet]
        }`,
        `${src}?$${templates[WindowFormat.desktop]}$&fmt=auto ${
          responsiveImageWidthParams[WindowFormat.desktop]
        }`,
        `${src}?$${templates[WindowFormat.wideDesktop]}$&fmt=auto ${
          responsiveImageWidthParams[WindowFormat.wideDesktop]
        }`,
      ].join(",")
    : "";
};
