import { format } from "url";

import { ImageSource } from "~/bff/types/ImageSource";
import { Nullable } from "~/types/general.types";

export const getImageSrc = (image?: Nullable<ImageSource>): string => {
  if (!image?.endpoint || !image?.name || !image?.defaultHost) {
    return "/assets/images/no-image.png";
  }

  return format({
    protocol: "https:",
    host: image?.defaultHost,
    pathname: `/i/${encodeURIComponent(image?.endpoint)}/${encodeURIComponent(
      image?.name,
    )}`,
  });
};
