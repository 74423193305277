"use client";

import React from "react";

import { Image } from "~/components/image/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getImageSrc } from "~/utils/image/get-image-src";

import { dimensions, imageTemplates } from "./constants";
import type { LogoProps } from "./types";

export const Logo: React.FC<LogoProps> = (props) => {
  const { className, image, size = "large" } = props;

  return (
    <Image
      className={className}
      imageTemplates={imageTemplates}
      alt={prepareTextAttribute(image?.alt) ?? "Logo"}
      title={prepareTextAttribute(image?.title)}
      width={dimensions.width[size]}
      height={dimensions.height[size]}
      src={image?.source ? getImageSrc(image.source) : undefined}
      {...getTestAutomationProps("logo")}
    />
  );
};
